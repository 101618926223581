var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "one_container",
      style: {
        background: "" + _vm.data.background
      }
    },
    [
      _vm._l(_vm.data.floor, function(item) {
        return [
          item.type == 0
            ? _c(
                "div",
                { key: item.id, staticClass: "banner" },
                [_c("Banner", { attrs: { list: item.config.banner } })],
                1
              )
            : _vm._e(),
          item.type == 1
            ? _c("goods-card-list", {
                key: item.id,
                attrs: {
                  config: item.config,
                  title: item.title,
                  background: item.background,
                  tempType: _vm.data.tempType
                }
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }